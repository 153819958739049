import React from 'react';
import Footer from './footer';
import '../styles/layout.css';
import Seo from "./seo";
import {Helmet} from "react-helmet";

const Layout = ({children}) => {
    return (
        <div className="page-container">
            <Seo />
            <Helmet htmlAttributes={{lang: 'en'}} />
            <div className="content-wrap">
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
