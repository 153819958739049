import React from "react";
import {graphql} from "gatsby";
import BlogItem from "../components/blog-item";
import "../styles/blog.css";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Navbar from "../components/navbar";

const Blog = ({data}) => {
    const {posts} = data.blog;

    return (
        <Layout>
            <Seo title="Clayton Davidson - Blog" description="Browse blog posts concerning current trends in tech and programming."/>
            <Navbar />
                <div className="blog-container">
                    {posts.map(post => (
                       <BlogItem key={post.id} item={post}/>
                    ))}
                </div>
        </Layout>
    );
};

export const pageQuery = graphql`
  query getBlogPosts {
    blog: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
     ){
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          image
          category
          desc
          alt
        }
        excerpt
        id
      }
    }
  }
`;

export default Blog;
