import React from 'react';
import {Link} from "gatsby";

const BlogItem = ({item}) => {
    //console.log(item);
    return (
        <article className="blog-item" key={item.id}>
            <a href={`/blog/${item.frontmatter.category.toLowerCase()}${item.fields.slug}`} aria-label={item.frontmatter.alt}>
                <img className="blog-image" src={item.frontmatter.image} alt={item.frontmatter.alt} aria-label="to recent post"/>
            </a>
            <div className="blog-category">
                {item.frontmatter.category}
            </div>
            <Link className="blog-link" to={`/blog/${item.frontmatter.category.toLowerCase()}${item.fields.slug}`}>
                <h2 className="blog-title">{item.frontmatter.title}</h2>
            </Link>
            <small className="blog-date">
                {item.frontmatter.date}
            </small>
            <p className="blog-excerpt">{item.frontmatter.desc}</p>
        </article>
    )
}

export default BlogItem;
