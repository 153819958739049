import React, {useState} from 'react';
import {Link} from 'gatsby';
import '../styles/navbar.css';
import {FaTimes} from 'react-icons/fa';
import {IoReorderThree} from "react-icons/io5";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <nav className="navbar">
                <div className={click ? "navbar-menu-icon" : "navbar-menu-icon wiggle"} role="button" tabIndex="0" aria-label="navbar button" onClick={handleClick}>
                    {click ? <FaTimes size={35} /> : <IoReorderThree size={35}/>}
                </div>
                <div className={click ? 'navbar-menu active' : 'navbar-menu'}>
                    <Link className="navbar-links" to="/" onClick={closeMobileMenu} aria-label="View home">
                        Home
                    </Link>
                    <Link className="navbar-links" to="/blog" onClick={closeMobileMenu} aria-label="View blog">
                        Blog
                    </Link>
                    <a className="navbar-links" href="https://drive.google.com/file/d/1bOr6b9gB3o6pg_8J-gwZ-cnMmnoNXbjT/view?usp=sharing" aria-label="View resume" target="_blank" rel="noreferrer">
                        Resume
                    </a>
{/*
                    <Link className="navbar-links" onClick={closeMobileMenu} to="/cool-stuff" aria-label="View cool stuff">
                        Cool Stuff
                    </Link>
*/}
                </div>
            </nav>
        </>
    )
}

export default Navbar;
