import React from 'react';
import "../styles/footer.css";
import {FaGithubSquare, FaLinkedin, FaAt} from 'react-icons/fa';

const Footer = () => {
/*
    TODO Change the color of the blog posts title on the blog page
    TODO Basically redo the entire blog page
    TODO Maybe add more content to the footer such as a copyright and "Connect with me" idk
*/
    const footer_links = {
        email: "mailto:clayton.lake.davidson@gmail.com",
        github: "https://www.github.com/claytondavidson",
        linkedin: "https://www.linkedin.com/in/clayton-davidson"
    }

    return (
        <div className="footer-content">
            <div className="footer-icons">
                <a className="footer-icon" href={footer_links.email} target="_blank" rel="noreferrer" aria-label="email icon">
                    <FaAt size={25}/>
                </a>
                <a className="footer-icon" href={footer_links.github} target="_blank" rel="noreferrer" aria-label="github icon">
                    <FaGithubSquare size={25}/>
                </a>
                <a className="footer-icon" href={footer_links.linkedin} target="_blank" rel="noreferrer" aria-label="linkedin icon">
                    <FaLinkedin size={25}/>
                </a>
            </div>
        </div>
    )
}

export default Footer;
